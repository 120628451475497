<template>
  <div class="main-content">
    <!-- <leaderboard></leaderboard> -->
     
    <news-list></news-list>
    <!-- <chart-list></chart-list> -->
    <hr/>
    <dpo-request></dpo-request>  
    <hr/>
    <crime-list></crime-list>
  </div>
</template>

<script>
import axios from "axios";
// import RequestList from './subcomponent/RequestList.vue';
import DpoRequest from '../idp_dpo/Dpo.vue';
import ChartList from './subcomponent/ChartList.vue';
import Leaderboard from './subcomponent/Leaderboard.vue';
import CrimeList from './subcomponent/CrimeList.vue';
import NewsList from './subcomponent/News.vue';
export default {
  name: 'Dashboard',
  components: {
    DpoRequest,
    ChartList,
    Leaderboard,
    CrimeList,
    NewsList
  },
  data() {
    return {
      
    };
  },
  methods: {

  }
}
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column-reverse;
}
</style>