<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h3 class="mb-0">Catatan Kriminal Terbaru</h3>
          <b-button size="sm" variant="primary" @click="getData()"> <i class="fa fa-undo" /> Refresh</b-button>
        </div>
        <b-table striped hover :items="tableData" :fields="fields1" :per-page="perPage" :current-page="currentPage" show-empty>
          <template #cell(photo)="data">
            <img :src="require('@/assets/images/avatars/' + data.item.photo + '.png')" class="profile-img" alt="Profile Image">
          </template>
          <template #empty>
            <div class="text-center my-3">
              Tidak ada data yang tersedia saat ini.
            </div>
          </template>
        </b-table>
        <br/>
        <div class="d-flex justify-content-end">
          <b-pagination v-model="currentPage" :total-rows="tableData.length" :per-page="perPage"></b-pagination>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h3 class="mb-0">Kriminal Warga diatas 15x</h3>
          <b-button size="sm" variant="primary" @click="getData()"><i class="fa fa-undo" /> Refresh</b-button>
        </div>
          <b-table striped hover :items="tableDataKriminalBerat" :fields="fieldsKriminalBerat" show-empty>
            <template #cell(photo)="data">
              <img :src="require('@/assets/images/avatars/' + data.item.photo + '.png')" class="profile-img" alt="Profile Image">
            </template>
            <template #empty>
              <div class="text-center my-3">
                Tidak ada data yang tersedia saat ini.
              </div>
            </template>
          </b-table>  
      </div>
      <div class="col-md-6">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h3 class="mb-0">Kriminal Kendaraan diatas 8x</h3>
          <b-button size="sm" variant="primary" @click="getData()"><i class="fa fa-undo" /> Refresh</b-button>
        </div>
          <b-table striped hover :items="tableKendaraanKriminalBerat" :fields="fieldsKriminalBerat" show-empty>
            <template #cell(photo)="data">
              <img :src="require('@/assets/images/avatars/' + data.item.photo + '.png')" class="profile-img" alt="Profile Image">
            </template>
            <template #empty>
              <div class="text-center my-3">
                Tidak ada data yang tersedia saat ini.
              </div>
            </template>
          </b-table>  
      </div>
    </div>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';
export default {
  name: 'Dashboard',
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    axios
  },
  data() {
    return {
      tableFields: ['id', 'name', 'email'],
      tableData: [],
      tableDataKriminalBerat: [],
      tableKendaraanKriminalBerat: [],
      perPage: 5, // Jumlah item per halaman
      currentPage: 1, // Halaman saat ini
      tableData1: [
        { id: 1, photo:'1-small',name: 'John Doe', email: 'john@example.com' },
        { id: 2, photo:'2-small',name: 'John Doe', email: 'jane@example.com' },
        { id: 3, photo:'3-small',name: 'John Doe', email: 'alice@example.com' }
      ],
      tableData2: [
        { id: 4, name: 'Bob Brown', email: 'bob@example.com' },
        { id: 5, name: 'Emma Lee', email: 'emma@example.com' },
        { id: 6, name: 'Chris Davis', email: 'chris@example.com' }
      ],
      fields1: [
        {key: 'name', label: 'Name' },
        {key: 'inserted_date', label: 'Tanggal' },
        {key: 'judul', label: 'Judul' }
      ],
      fieldsKriminalBerat: [
        // {key: 'biodata', label: 'Data Kriminal Berat' }
        {key: 'fullname', label: 'Name' },
        {key: 'total_kriminal_berat', label: 'Total Kriminal Berat' },
      ],
    };
  },
  methods: {
    init(){
      this.getData();
    },
    getData() {
      axios.post("idp/dashboard/crime-list",{id:null}).then((response) => {
        this.tableData = response.data.crime
        this.tableDataKriminalBerat = response.data.crime_person
        this.tableKendaraanKriminalBerat = response.data.crime_vehicle
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
        this.modalShow = false;
      });
    },
  },
  mounted(){
    this.init();
  }
}
</script>