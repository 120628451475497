<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <kriminal></kriminal>
      </div>
      <div class="col-md-4">
        <tangkap-dpo></tangkap-dpo>
      </div>
      <div class="col-md-4">
        <tangkap-dpk></tangkap-dpk>
      </div>
    </div>
  </div>
</template>

<script>
import Kriminal from './chart/Kriminal.vue';
import TangkapDpo from './chart/TangkapDpo.vue';
import TangkapDpk from './chart/TangkapDpk.vue';
export default {
  name: 'Dashboard',
  components: {
    Kriminal,
    TangkapDpo,
    TangkapDpk,
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>