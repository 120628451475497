<template>
  <b-container v-if="content == true">
    <!-- <b-row>
      <b-col md="12" class="mx-auto">
        <b-card class="mb-3">
          <h3 style="text-align: center;margin-bottom: 50px;"> {{ news.title }} </h3>
          <div v-html="news.content"></div>
        </b-card>
      </b-col>
    </b-row> -->
    <b-modal v-model="content" centered :title="news.title" hide-footer size="lg">
      <div v-html="news.content"></div>
    </b-modal> 
  </b-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      Loading: false,
      content:false,
      news:'',
    };
  },
  methods: {
    init(){
      this.getData();
    },
    getData(){
      axios.post("idp/master/news/display").then((response) => {
        this.news = response.data.data
        if(this.news.id){
          this.content = true
        }else{
          this.content = false
        }
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
        this.modalShow = false;
      });
    }
  },
  mounted(){
    this.init();
  }
};
</script>

<style scoped>
/* Gaya khusus untuk komponen ini */
.card {
  border-radius: 10px; /* Sudut card */
}

.card-title {
  font-size: 22px; /* Ukuran teks judul */
  font-weight: bold; /* Ketebalan teks judul */
}

.card-sub-title {
  font-size: 14px; /* Ukura
